@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow: hidden;
  background-color: #000;
  font-family: "Raleway", sans-serif;
}

.monster {
  font-family: "Montserrat", sans-serif;
}

.sidekick{
  font-family: "Inter", sans-serif;
}

.bg-image {
  background-image: url("../src/assets/newbg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 102%;
  margin-left: -2vh;
  height: 100vh;
  font-family: "Montserrat", sans-serif;
};

.submitbtn{
  background: linear-gradient(90deg, #FFF000 0%, #FF8A00 100%);
}

.customtext{
  color:linear-gradient(90deg, #FFF000 0%, #FF8A00 100%);;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 23px;
  height: 22px;
  border: 3px solid rgba(31, 0, 0, 0.493);
  border-top-color: #fff;
  border-radius: 50%;
  animation: spinner 0.6s linear infinite;
}

.webkit-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #111;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #ffee0095;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #000;
  border-radius: 10px;
}

.scrollbar-hide {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}