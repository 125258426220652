.submitbtn{
  background: linear-gradient(90deg, #FFF000 0%, #FF8A00 100%);
}

.glassmorphism-container {
  background: rgba(255, 255, 255, 0.041); /* Semi-transparent background */
  backdrop-filter: blur(100px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border-radius: 15px; /* Rounded corners */
  border: 2px solid rgba(255, 255, 255, 0.05); /* Subtle border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

.blurclass{
  filter: blur(500px);
}

.customtext {
  background: linear-gradient(90deg, #FFF000 0%, #FF8A00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

body {
  overflow-x: hidden;
  background-color: #000;
}

.App{
  background-color: #000;
}

/* Hide the default file input */
#file-upload {
  display: none;
}

/* Custom Browse Button Styling */
.custom-file-upload {
  display: inline-block;
  padding: 1vh;
  font-size: 2.5vh;
  cursor: pointer;
  background-color: #fff333;
  color: #333;
  border-radius: 5px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
}

.custom-file-upload:hover {
  background-color: #ffcc00;
}

.custom-file-upload:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.scrollbar-hide {
  -ms-overflow-style: none; 
  scrollbar-width: none;  
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;  
}